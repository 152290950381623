import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const WebinarWith2012WinnerDeepikaKurup = () => (
  <Layout
    title="All About the Young Scienitst Challenge: Webinar with 2012 Winner Deepika Kurup"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            All About the Young Scienitst Challenge: Webinar with 2012 Winner
            Deepika Kurup
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">
              All About the Young Scienitst Challenge: Webinar with 2012 Winner
              Deepika Kurup
            </h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <div className="videoWrapper">
                <Link to="../process-this-analyze-that" className="previous">
                  <Icon name="arrowleftgray" />
                </Link>
                <Video youTubeID="D1-rZSgs3eI" className="youtube"></Video>
                <Link
                  to="../ysc-webinar-2011-winner-braeden-benedict"
                  className="next"
                >
                  <Icon name="arrowrightgray" />
                </Link>
              </div>
            </div>
            <h3>
              Learn all about the Discovery Education 3M Young Scientist
              Challenge in this webinar featuring 2012 Grand Prize winner
              Deepika Kurup!
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default WebinarWith2012WinnerDeepikaKurup;
